import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import firebase from 'firebase/app'
import { Link as ScrollLink } from 'react-scroll'
import { useRouter } from 'next/router'
import { getItem, setItem, removeItem } from '../../lib/asyncStorage'
import { logSegEvent } from '../../lib/analytics'
import { BUTTON_CLICK } from '../../constants/analytics'
import { useInviteURL, useCloudFn, useAuthWithToken } from '../../hooks'
import { logMsg } from '../../lib/err'
const star = '/static/landslide/introduction/MadeInUsaStar.png'
const img1 = '/static/landslide/introduction/Efficiancy.png'
const img2 = '/static/landslide/introduction/CrewMembers.png'
const img3 = '/static/landslide/introduction/powerscore.png'
const imgUrl = `/static/landslide/icons/CrewMemberGrey.png`

const experiment = 'intro'
const variant = 'long-1'

export default function IntroLong() {
  const [openFaq, setOpenFaq] = useState(null)
  const [captainInfo, setCaptainInfo] = useState()
  const [loading, setLoading] = useState(true)
  const [hasInvite, setHasInvite] = useState(false)
  const { query } = useRouter()
  const getCaptainInfo = firebase.functions().httpsCallable('getCaptainInfo')
  let hrefLink = '/join'
  // persist crew or refer link across page transitions
  if (query.c) {
    hrefLink = `${hrefLink}?c=${query.c}`
  } else if (query.r) {
    hrefLink = `${hrefLink}?r=${query.r}`
  }

  useEffect(() => {
    let crewInvite = Object.prototype.hasOwnProperty.call(query, 'c') ? query.c : null
    const fetchCaptain = async () => {
      try {
        const crewStorageRef = await getItem('crew')
        if (!crewInvite && crewStorageRef) {
          crewInvite = crewStorageRef
        }
        if (crewInvite) {
          setHasInvite(true)
          const { data } = await getCaptainInfo({ crew: crewInvite })
          setCaptainInfo(data)
        } else {
          // try refer invite
          let referInvite = Object.prototype.hasOwnProperty.call(query, 'r') ? query.r : null
          const referStorageRef = await getItem('refer')
          if (!referInvite && referStorageRef) {
            referInvite = referStorageRef
          }
          if (referInvite) {
            setHasInvite(true)
            const { data } = await getCaptainInfo({ refer: referInvite })
            setCaptainInfo(data)
          }
        }
      } catch (e) {
        logMsg('Failed to fetch captain info in intro')
      } finally {
        setLoading(false)
      }
    }
    fetchCaptain()
  }, [])

  const faqClick = (index) => {
    if (index === openFaq) {
      setOpenFaq(null)
    } else {
      setOpenFaq(index)
    }
  }

  const initial = captainInfo?.fname.charAt(0).toUpperCase() || ''

  const icon = (index) => {
    return index === openFaq ? '-' : '+'
  }

  return (
    <div id="intro-long">
      <div id="top-wrapper">
        <div id="top-wrapper-content">
          <div id="top-row">
            <ScrollLink to="faq" smooth="true">
              <button id="faq-button" type="button">
                FAQ
              </button>
            </ScrollLink>
            <h1>Landslide</h1>
            <Link href="/login">
              <button id="login-button" type="button" onClick>
                Login
              </button>
            </Link>
          </div>
          {!loading && captainInfo?.fname && (
            <div id="has-captain">
              <div id="invite-pic">
                {captainInfo?.photoURL ? (
                  <img src={captainInfo.photoURL} alt="captain profile" />
                ) : (
                  <div className="initial">
                    <p>{initial}</p>
                  </div>
                )}
              </div>
              {captainInfo?.fname && <p>{captainInfo.fname} invited you to join their Landslide and help swing the election for the Democrats. </p>}
              <Link href={hrefLink}>
                <button
                  id="gtm-intro-join-button-top"
                  className="btn btn-primary"
                  onClick={() =>
                    logSegEvent(BUTTON_CLICK, {
                      experiment,
                      variant,
                      tag: 'cta-btn-1',
                    })
                  }
                >
                  Get Started
                </button>
              </Link>
              )
            </div>
          )}
          {!loading && !captainInfo?.fname && (
            <div id="no-captain">
              <h1>
                Welcome to <span>Landslide</span>
              </h1>
              <h3>The most effective thing you can do to help Democrats win this election is commit to vote with your friends.</h3>
              <Link href={hrefLink}>
                <button
                  id="gtm-intro-join-button-top"
                  className="btn btn-primary"
                  onClick={() =>
                    logSegEvent(BUTTON_CLICK, {
                      experiment,
                      variant,
                      tag: 'cta-btn-1',
                    })
                  }
                >
                  Get Started
                </button>
              </Link>
            </div>
          )}
        </div>
        <img id="star" src={star} alt="made by volunteers" />
      </div>
      <section className="barchart-section">
        {!hasInvite && (
          <div id="has-no-invite">
            <h2>Everyone who votes thinks their friends vote too.</h2>
            <h2>It turns out, they are wrong over 50% of the time.</h2>
            <p>People who commit to vote with their friends are dramatically more likely to cast a ballot.</p>
          </div>
        )}
        {hasInvite && (
          <div id="has-invite">
            <h2>What is landslide?</h2>
            <h3>It’s how we win the election:</h3>
            <h3> You get your friends, they get their friends, they get their friends - then we all vote.</h3>
            <hr />
            <p>
              Everyone who votes thinks their friends vote too. It turns out, they are wrong over 50% of the time. People who commit to vote with
              their friends are 5-20x more likely to cast a ballot.
            </p>
          </div>
        )}
      </section>
      <img className="side-padding" src={img1} alt="efficiancy chart" />
      <section>
        <h2>Get everything you need</h2>
        <p>Landslide gives you and your "crew" everything you need to register and vote in the 2020 election.</p>
      </section>
      <img src={img2} alt="crew members" />
      <section className="track-your-impact">
        <h2>Track your impact</h2>
        <p>
          Landslide is the only place where you can see the exact number of people who commit to vote because of you... <span>before</span> election
          day.
        </p>
      </section>
      <img src={img3} alt="impact numbers" />
      <div id="bottom-cta">
        <h2>Ready to influence elections?</h2>
        <h2>Start your Landslide</h2>
        <Link href={hrefLink}>
          <button
            id="gtm-intro-join-button-bottom"
            className="btn btn-primary join-us"
            onClick={() =>
              logSegEvent(BUTTON_CLICK, {
                experiment,
                variant,
                tag: 'cta-btn-2',
              })
            }
          >
            Get started
          </button>
        </Link>
      </div>
      <div id="faq">
        <div className="content">
          <h2>FAQs</h2>
          <button onClick={() => faqClick(0)} className="btn">
            <p>{icon(0)}</p>
            <p>What if my friends already vote?</p>
          </button>
          {openFaq === 0 && <p>{answers[0]}</p>}
          <button onClick={() => faqClick(1)} className="btn">
            <p>{icon(1)}</p>
            <p>Who made Landslide?</p>
          </button>
          {openFaq === 1 && <p>{answers[1]}</p>}
          <button onClick={() => faqClick(2)} className="btn">
            <p>{icon(2)}</p>
            <p>Are you going to spam me and my friends?</p>
          </button>
          {openFaq === 2 && <p>{answers[2]}</p>}
          <button onClick={() => faqClick(3)} className="btn">
            <p>{icon(3)}</p>
            <p>Is it time consuming?</p>
          </button>
          {openFaq === 3 && <p>{answers[3]}</p>}
          <button onClick={() => faqClick(4)} className="btn">
            <p>{icon(4)}</p>
            <p>How do you track my impact?</p>
          </button>
          {openFaq === 4 && <p>{answers[4]}</p>}
          <button onClick={() => faqClick(5)} className="btn">
            <p>{icon(5)}</p>
            <p>How do you know people who sign up are going to vote?</p>
          </button>
          {openFaq === 5 && <p>{answers[5]}</p>}
          <a target="_blank" rel="noreferrer" href="mailto:support@landslide2020.org">
            <button id="gtm-intro-contact-us" className="btn">
              Contact Us
            </button>
          </a>
        </div>
      </div>
    </div>
  )
}

const answers = [
  "People who vote believe all their friends vote, too. According to recent research, however, they are right only ~50% of the time. The research also suggests that voting is a social behavior; friends who know their friends vote are more likely to vote. But, how do you know if your friends will actually vote? Landslide provides visibility before the election and it's just enough to get folks who might not vote to show up at the polls.",
  'Landslide is designed and built in the US by a team of progressive volunteers. We believe another Trump presidency represents an existential threat to both democracy and the planet, so we decided to do everything we could to stop it.',
  "Truth is, sending SMS messages is really expensive and we're not taking money from billionaires to fund this. Between now and the election, we'll send you around 5-10 messages, mainly just to tell you how awesome you are and show you the exact number of people who are committing, registering and voting, because of you.",
  'Nope. It takes about 30 seconds to sign up and in 2 minutes you can invite a handful of friends.',
  `When someone signs up for Landslide with your link we count them toward your "Power Score", which is the exact number of people who've signed up because of you. When the people you recruit (or the people they recruit) invite more people, we count them towards your Score, too. A few days after inviting your friends, they will have recruited 20-30 more people. A week after that, it's 70-100. All with a few minutes of effort. People who spend more time or have a big following can easily turnout hundreds or thousands of voters.`,
  `In multiple large, well documented studies on voter behavior, friends increase the probability that their friends will vote by 20%. That might not sound like much, but 20% is enough to swing almost every single election in the country.`,
]
