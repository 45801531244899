import React, { useEffect } from 'react'
import Layout from '../components/Layout'
import IntroLong from '../components/introduction/IntroLong'
import { logPageView } from '../lib/analytics'

export default function Intro() {
  useEffect(() => {
    logPageView('introduction-1')
  }, [])

  return (
    <Layout fullScreen title="Introduction">
      <div id="introduction">
        <IntroLong />
      </div>
    </Layout>
  )
}
